<template>
  <v-row justify="center" class="mb-1">
    <v-col
      class="px-3 card-calendar"
      >
      <!-- :class="{
         'card-calendar': $vuetify.breakpoint.mdAndUp,
         'card-calendar-sm': $vuetify.breakpoint.smOnly,
         'card-calendar-xs': $vuetify.breakpoint.xsOnly,
       }" -->
      <v-card :elevation="1" class="pb-0 rounded-sm fill-height" :loading="true">

        <template #progress>
          <v-progress-linear
            :value="100"
            :color="progressColor"
          />
        </template>
        <v-card-title class="py-2 text-h5 d-flex flex-column align-items-center justify-content-center">
          <span class="primary--text font-weight-bold text-capitalize">
            {{ dia.fecha | diaSemana }}
            {{ Object.keys(dia).length > 0 ? momento(dia.fecha).format("DD") : '' }}
          </span>
          <span class="text-uppercase caption font-weight-regular blue-grey--text">
            {{ dia.fecha | mes }}
          </span>
        </v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import moment from 'moment'
import {totalClientesNoPlanificado} from '@/util/helpers'
import es from 'moment/locale/es'
moment.locale('es', es)

const totalDuracionVisita = (item) => {
  if (item.clientes.length > 0) {
    const duration = item.clientes.reduce((acc, cli) => {
      if (cli?.visita?.hora_inicio && cli?.visita?.hora_fin) {
        const inicio = moment(`${cli.visita.fecha} ${cli.visita.hora_inicio}`);
        const fin = moment(`${cli.visita.fecha} ${cli.visita.hora_fin}`);
        return acc + fin.diff(inicio)
      }
      return acc
    }, 0)
    const hours = Math.floor(moment.duration(duration).asHours()).toString()

    return `${ hours.length < 2 ? hours.padStart(2, '0') : hours }:${ moment.utc(duration).format("mm:ss") }`
  }
  return null

}

export default {
  name: 'HeaderCalendar',
  props:{
    semana: {
      type: Array,
      default: () => ([]),
    },
    dia: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    colorsProgress: ['blue', 'purple', 'orange', 'pink lighten-1', 'indigo', 'teal'],
  }),
  methods:{
    momento: moment,
  },
  computed: {
    progressColor () {
      return Object.keys(this.dia).length > 0 && this.dia.dia_semana ? this.colorsProgress[this.dia.dia_semana - 1] : 'blue-grey'
    },
  },
  filters: {
    diaMes(date) {
      return moment(date).format("DD")
    },
    diaSemana(date) {
      return moment(date).format("dddd")
    },
    mes(date) {
      return moment(date).format("MMMM");
    },
    hora(time) {
       return time != "null" ? moment(time).format('hh:mm a') : '';
    },
  }
}
</script>
<style>
.label-visitas {
  font-size: 0.895rem !important;
  letter-spacing: 0.1rem;
  line-height: 0.625rem;
  text-transform: uppercase;
  font-family: "Open Sans" !important;
}
</style>
